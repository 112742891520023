<template>
    <div></div>
</template>

<script>
export default {    
    mounted() {
        this.$router.push('/individual')
    },
};
</script>