<template>
    <div class="accounts">
        <div class="banner-container">
            <div class="banner-content">
                <div v-for="item in getClientsPosts" :key="item.id" class="card" :class="card == item.id ? 'active-card' : ''" @click="changeCard(item.id), addCustomParameter(item.id)">
                    <span class="card-title">
                        <h1 v-if="item.name.includes('llogaris')">
                            <span sq="Aplikim për hapje të llogarisë Individuale" en="Application for Individual Account Opening" sr="Prijava za otvaranje individualnog računa"></span>
                        </h1>
                        <h1 v-if="item.name.includes('afatizim')">
                            <span sq="Aplikim për afatizim të mjeteve" en="Application for Fund Term Deposit" sr="Prijava za štednju na određeno vreme"></span>
                        </h1>
                        <h1 v-if="item.name.includes('Kredi Individuale')">
                            <span sq="Aplikim për Kredi Individuale" en="Application for Personal Loans" sr="Prijava za lične kredite"></span>
                        </h1>
                        <h1 v-if="item.name.includes('Overdraft')">
                            <span sq="Aplikim për Overdraft" en="Application for Overdraft" sr="Prijava za prekoračenje"></span>
                        </h1>
                        <h1 v-if="item.name.includes('E-Banking')">
                            <span sq="Aplikim për E-Banking" en="Application for E-Banking" sr="Prijava za E-Banking"></span>
                        </h1>
                        <h1 v-if="item.name.includes('M-Banking')">
                            <span sq="Aplikim për M-Banking" en="Application for M-Banking" sr="Prijava za M-Banking"></span>
                        </h1>
                    </span>         
                </div>  
            </div>
        </div>
        <div>
            <div v-for="item in getClientsPosts" :key="item.id">
                <div v-if="card == 14 && item.id == 14">
                    <div>
                        <IndividualLoanApplication />
                    </div>
                </div>
                <div v-if="card == 11 && item.id == 11">
                    <div>
                        <IndividualFundsLimitation />
                    </div>
                </div>
                <div v-if="card == 12 && item.id == 12">
                    <div>
                        <IndividualEBankingApplication />
                    </div>
                </div>
                <div v-if="card == 13 && item.id == 13">
                    <div>
                        <IndividualAccountApplication />
                    </div>
                </div>
                <div v-if="card == 16 && item.id == 16">
                    <div>
                        <IndividualOverdraftApplication />
                    </div>
                </div>
                <div v-if="card == 15 && item.id == 15">
                    <div>
                        <IndividualMBankingApplication />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import IndividualAccountApplication from "@/components/Individual/IndividualAccountApplication.vue";
    import IndividualLoanApplication from "@/components/Individual/IndividualLoanApplication.vue";
    import IndividualOverdraftApplication from "@/components/Individual/IndividualOverdraftApplication.vue";
    import IndividualFundsLimitation from "@/components/Individual/IndividualFundsLimitation.vue";
    import IndividualEBankingApplication from "@/components/Individual/IndividualEBankingApplication.vue";
    import IndividualMBankingApplication from "@/components/Individual/IndividualMBankingApplication.vue";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                card: 1,
            }
        },
        components: {
            IndividualLoanApplication,
            IndividualAccountApplication,
            IndividualOverdraftApplication,
            IndividualFundsLimitation,
            IndividualEBankingApplication,
            IndividualMBankingApplication
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            changeCard(e) {
                console.log(e);
                this.card = e;
            },
            addCustomParameter(i) {
                this.$router.push({ query: { form: i } })
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getClients() {
                return this.getCategories.find(x => x.slug === 'online-applications')
            },
            getClientsPosts() {
                return this.getCategories.filter(x => x.parent === this.getClients.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            }
        },
        async mounted() {
            this.card = this.getClientsPosts[0].id
            if (this.$route.query.form !== undefined) {
                this.changeCard(this.$route.query.form)
            }
            this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();
            setTimeout(()=> externalScript.reloadLanguage(), 300);

           
        },
    };
</script>

<style>
    @import "../../assets/styles/individual-accounts.css";
</style>