<template>
    <div class="accounts">
        <div class="banner-container">
            <div class="banner-content">
                <div v-for="item in getClientsPosts" :key="item.id" class="card" :class="card == item.id ? 'active-card' : ''" @click="changeCard(item.id)">
                    <span class="card-title" v-if="item.name.includes('Mbitërheqja')">
                        <h1  sq="Mbitërheqja" en="Overdraft" sr="Prekoračenje"></h1>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Kartelat')">
                        <h1  sq="Kartelat" en="Cards" sr="Kartice"></h1>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Sherbimet Elektronike')">
                        <h1  sq="Sherbimet Elektronike" en="Electronic Services" sr="Elektronske usluge"></h1>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Kreditë')">
                        <h1  sq="Kreditë" en="Loans" sr="Krediti"></h1>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Depozitat')">
                        <h1  sq="Depozitat" en="Deposits" sr="Depoziti"></h1>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Transfere dhe pagesa')">
                        <h1  sq="Transfere dhe pagesa" en="Transfers and payments" sr="Transferi i plaćanja"></h1>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Llogarit')">
                        <h1  sq="Llogaritë" en="Accounts" sr="Računi"></h1>
                    </span>
                </div>
            </div>
        </div>

        <div v-if="card == 74" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getOverdraft[0].meta._sq_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getOverdraft[0].meta._en_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getOverdraft[0].meta._sr_post_content"></span>
            </div>
        </div>

        <div v-if="card == 68" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getCreditCards[0].meta._sq_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getCreditCards[0].meta._en_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getCreditCards[0].meta._sr_post_content"></span>
            </div>
        </div>

        <div v-if="card == 36" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getAccounts[0].meta._sq_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getAccounts[0].meta._en_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getAccounts[0].meta._sr_post_content">
                </span>
            </div>
        </div>

        <div v-if="card == 35" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getTransfers[0].meta._sq_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getTransfers[0].meta._en_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getTransfers[0].meta._sr_post_content">
                </span>
            </div>
        </div>

        <div v-if="card == 69" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getService[0].meta._sq_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getService[0].meta._en_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getService[0].meta._sr_post_content">
                </span>
            </div>
        </div>

        <div v-if="card == 34" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getDeposits[0].meta._sq_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getDeposits[0].meta._en_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getDeposits[0].meta._sr_post_content">
                </span>
            </div>
        </div>

        <div v-if="card == 33" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getKredit[0].meta._sq_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getKredit[0].meta._en_post_content">
                </span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getKredit[0].meta._sr_post_content">
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                card: 1,
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            changeCard(e) {
                console.log(e);
                this.card = e;
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getClients() {
                return this.getCategories.find(x => x.slug === 'klientet')
            },
            getClientsPosts() {
                return this.getCategories.filter(x => x.parent === this.getClients.id)
            },
            getOverdraft(){
                return this.sortedPosts.filter(x => x.id == 1283);
            },
            getCreditCards() {
                return this.sortedPosts.filter(x => x.id == 769);
            },
            getAccounts() {
                return this.sortedPosts.filter(x => x.id == 763);
            },
            getTransfers() {
                return this.sortedPosts.filter(x => x.id == 765);
            },
            getDeposits() {
                return this.sortedPosts.filter(x => x.id == 767);
            },
            getKredit() {
                return this.sortedPosts.filter(x => x.id == 772);
            },
            getService() {
                return this.sortedPosts.filter(x => x.id == 774);
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        async mounted() {
            this.card = this.getClientsPosts[0].id
            this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();
            setTimeout(() => externalScript.reloadLanguage(), 250);
        },
    };
</script>

<style>
    @import "../../assets/styles/individual-accounts.css";
</style>