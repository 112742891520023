import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Individual/Home.vue";
import Rates from "@/components/Shared/Rates.vue";
import FAQ from "@/components/Shared/FAQ.vue";
import Organogram from "@/components/Shared/Organogram.vue";
import Banks from "@/components/Shared/Locations/~.vue";
import BankPricing from "@/components/Shared/BankPricing.vue";
import ListSSI from "@/components/Shared/ListSSI.vue";
import Protection from "@/components/Shared/DataProtection.vue";
import ComplaintsSuggestions from "@/components/Shared/ComplaintsAndSuggestions.vue";
import GeneralConditions from "@/components/Shared/GeneralConditions.vue";
import GeneralAfarismConditions from "@/components/Shared/GeneralAfarismConditions.vue";
import AboutUs from "@/components/Individual/AboutUs.vue";
import IndividualAccounts from "@/components/Individual/Accounts.vue";
import CustomerCare from "@/components/Shared/CustomerCare.vue";
import OnlineApplication from "@/components/Individual/OnlineApplications.vue";
import IndividualCards from "@/components/Individual/Cards.vue";
import IndividualTransfersAndPayments from "@/components/Individual/TransfersAndPayments.vue";
import IndividualDepositTypes from "@/components/Individual/DepositTypes.vue";
import IndividualCreditTypes from "@/components/Individual/CreditTypes.vue";
import IndividualAccountApplication from "@/components/Individual/IndividualAccountApplication.vue";
import IndividualLoanApplication from "@/components/Individual/IndividualLoanApplication.vue";
import IndividualOverdraftApplication from "@/components/Individual/IndividualOverdraftApplication.vue";
import IndividualFundsLimitation from "@/components/Individual/IndividualFundsLimitation.vue";
import IndividualEBankingApplication from "@/components/Individual/IndividualEBankingApplication.vue";
import IndividualMBankingApplication from "@/components/Individual/IndividualMBankingApplication.vue";
import IndividualProductsAndServices from "@/components/Individual/ProductsAndServices.vue";
import Branches from "@/components/Shared/Locations/~.vue";
import BusinessHome from "@/components/Business/Home.vue";
import BusinessAccounts from "@/components/Business/Accounts.vue";
import Management from "@/components/Business/Management.vue";
import OnlineApplications from "@/components/Business/OnlineApplications.vue";
import BusinessLoanApplication from "@/components/Business/BusinessLoanApplication.vue";
import BusinessOverdraftApplication from "@/components/Business/BusinessOverdraftApplication.vue";
import BusinessEBankingApplication from "@/components/Business/BusinessEBankingApplication.vue";
import Posts from "@/components/PostList.vue";
import Announcement from "@/components/Shared/Announcement.vue"
import PostByID from "@/components/Shared/PostById.vue";
import ClassicDeposit from "@/components/Individual/ClassicDeposit.vue";
import AvansDeposit from "@/components/Individual/AvansDeposit.vue";
import ChildDeposit from "@/components/Individual/ChildDeposit.vue";
import EmptyRedirect from "@/components/EmptyRedirect.vue";
import Privacy from "@/components/Shared/Privacy.vue";


const routes = [
    {
        path: "/individual",
        name: "Home",
        component: Home,
    },
    {
        path: "/announcement",
        name: "Announcement",
        component: Announcement
    },
    {
        path: "/individual/exchange-currency",
        name: "Rates",
        component: Rates,
    },
    {
      path: "/individual/faq",
      name: "FAQ",
      component: FAQ,
    },
    {
        path: "/individual/classicDeposit",
        name: "ClassicDeposit",
        component: ClassicDeposit,
    },
    {
        path: "/individual/childDeposit",
        name: "ChildDeposit",
        component: ChildDeposit,
    },
    {
        path: "/individual/avansDeposit",
        name: "AvansDeposit",
        component: AvansDeposit,
    },
    {
      path: "/business/pyetje-te-zakonshme",
      name: "FAQs",
      component: FAQ,
    },
    {
        path: "/bank-branches",
        name: "Banks",
        component: Banks,
    },
    {
        path: "/business/organogram",
        name: "Organogram",
        component: Organogram,
    },
    {
        path: "/",
        name: "EmptyRedirect",
        component: EmptyRedirect,
    },
    {
        path: "/business",
        name: "Home1",
        component: BusinessHome,
    },
    {
        path: "/business/klientet",
        name: "BusinessAccounts",
        component: BusinessAccounts,
    },
    {
        path: "/business/menaxhmenti",
        name: "Management",
        component: Management,
    },
    {
        path: "/business/aplikimet-online",
        name: "OnlineApplications",
        component: OnlineApplications,
    },
    {
        path: "/business/business-loan-application",
        name: "BusinessLoanApplication",
        component: BusinessLoanApplication,
    },
    {
        path: "/business/business-overdraft-application",
        name: "BusinessOverdraftApplication",
        component: BusinessOverdraftApplication,
    },
    {
        path: "/business/business-e-banking-application",
        name: "BusinessEBankingApplication",
        component: BusinessEBankingApplication,
    },
    {
        path: "/business/ankesa-dhe-sugjerime",
        name: "AnkesaSugjerime",
        component: ComplaintsSuggestions,
    },
    {
        path: "/business/deget-e-bankes",
        name: "Deget",
        component: Branches,
    },
    {
        path: "/business/cmimorja-e-bankes",
        name: "CmimorjaEBankes",
        component: BankPricing,
    },
    {
        path: "/business/listassi",
        name: "ListSSI",
        component: ListSSI,
    },
    {
        path: "/business/kushtet-e-pergjithshme",
        name: "KushtetEPergjithshme",
        component: GeneralConditions,
    },
    {
        path: "/individual/kushtet-e-pergjithshme-te-afarizmit-bankar",
        name: "KushtetEPergjithshme",
        component: GeneralAfarismConditions,
    },
    {
        path: "/business/kushtet-e-pergjithshme-te-afarizmit-bankar-kujdesi-ndaj-klientit",
        name: "KushtetEPergjithshmeTeAfarizmitBankar",
        component: GeneralAfarismConditions,
    },
    {
        path: "/business/mbrojtja-e-te-dhenave",
        name: "MbrojtjaETeDhenave",
        component: Protection,
    },
    {
        path: "/individual/about-us",
        name: "IndividualAboutUs",
        component: AboutUs,
    },
    {
        path: "/business/rreth-nesh",
        name: "BusinessAboutUs",
        component: AboutUs,
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: Privacy,
    },
    {
        path: "/individual/clients",
        name: "IndividualAccounts",
        component: IndividualAccounts,
    },
    {
        path: "/individual/online-applications",
        name: "OnlineApplication",
        component: OnlineApplication,
    },
    {
        path: "/individual/cards",
        name: "IndividualCards",
        component: IndividualCards,
    },
    {
        path: "/individual/transfersAndPayments",
        name: "IndividualTransfersAndPayments",
        component: IndividualTransfersAndPayments,
    },
    {
        path: "/individual/depositTypes",
        name: "IndividualDepositTypes",
        component: IndividualDepositTypes,
    },
    {
        path: "/individual/individual-account-application",
        name: "IndividualAccountApplication",
        component: IndividualAccountApplication,
    },
    {
        path: "/individual/individual-loan-application",
        name: "IndividualLoanApplication",
        component: IndividualLoanApplication,
    },
    {
        path: "/individual/individual-overdraft-application",
        name: "IndividualOverdraftApplication",
        component: IndividualOverdraftApplication,
    },
    {
        path: "/individual/individual-funds-limitation",
        name: "IndividualFundsLimitation",
        component: IndividualFundsLimitation,
    },
    {
        path: "/individual/individual-e-banking-application",
        name: "IndividualEBankingApplication",
        component: IndividualEBankingApplication,
    },
    {
        path: "/individual/individual-m-banking-application",
        name: "IndividualMBankingApplication",
        component: IndividualMBankingApplication,
    },
    {
        path: "/individual/creditTypes",
        name: "IndividualCreditTypes",
        component: IndividualCreditTypes,
    },
    {
        path: "/individual/bankServices",
        name: "IndividualProductsAndServices",
        component: IndividualProductsAndServices,
    },
    {
        path: "/individual/complaints-suggestions" ,
        name: "ComplaintsSuggestions",
        component: ComplaintsSuggestions,
    },
    {
        path: "/individual/customer-care" ,
        name: "IndividualCustomerCare",
        component: CustomerCare,
    },
    {
        path: "/business/kujdesi-ndaj-klientit" ,
        name: "BusinessCustomerCare",
        component: CustomerCare,
    },
    {
        path: "/individual/bank-branches",
        name: "Branches",
        component: Branches,
    },
    {
        path: "/individual/bank-pricing",
        name: "BankPricing",
        component: BankPricing,
    },
    {
        path: "/individual/listassi",
        name: "ListSSI",
        component: ListSSI,
    },
    {
        path: "/individual/general-conditions",
        name: "GeneralConditions",
        component: GeneralConditions,
    },
    {
        path: "/individual/data-protection",
        name: "Protection",
        component: Protection,
    },
    {
        path: "/category/:id",
        name: "Posts",
        component: Posts,
    },
    {
        path: "/individual/post/:id(\\d+)",
        name: "PostsByID",
        component: PostByID,
    },
    {
        path: "/business/post/:id(\\d+)",
        name: "BusinessPostsByID",
        component: PostByID,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach(function () {
    window.scrollTo(0, 0);
});

export default router;
