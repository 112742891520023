import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "@/router";
import store from "./store";
//import VueGoogleMaps from "@fawmi/vue-google-maps";
axios.defaults.baseURL = "https://cms.pribank-ks.com/wp-json";
import './input.css'
const app = createApp(App);
const loader = document.getElementById("preloader");

app.use(router).use(store).mount("#app");

app.config.globalProperties.$http = axios;

//app.use(VueGoogleMaps, {
//	load: {
//		key: "AIzaSyDQgLyb04QZ5qp2hfp8Vzm7uJTYTt4v4Ds"
//	}
//});

axios.interceptors.response.use(response => {
	loader.style.display = "none";
	return response;
}, error => Promise.reject(error));