<template>
    <div class="deposits">
        <div class="banner-container">
            <div class="banner-content">
                <div class="banner-title">
                    <h1>Kushtet e përgjithshme</h1>
                    <p>
                        Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem
                        ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor.
                    </p>
                </div>
               
            </div>
        </div>
        <div class="deposits-container">
            <div v-for="i in 4" class="deposit-content" :key="i"
                 :style="i % 2 == 0 ? 'flex-direction: row-reverse; justify-content: flex-end;' : ''">
                <div class="deposit-post">
                    <h1>Kushtet e përgjithshme</h1>
                    <p class="information">
                        Let's spread the joy, here is Christmas, the most awaited day of the
                        year.Christmas Tree is
                        what one need the most. Here is the correct tree which will enhance your Christmas.
                    </p>
                </div>
                <div class="deposit-image">
                    <img src="https://images.unsplash.com/photo-1606830733744-0ad778449672?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mzl8fGNocmlzdG1hcyUyMHRyZWV8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                         alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    export default {
        data() {
            return {
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getAboutUs() {
                return this.getCategories.find(x => x.slug === 'about-us')
            },
            getAboutUsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            // showPost(){
            //   this.getPostMedia();
            //   let sortedPosts = [...this.getPosts].sort((a, b) => a.id - b.id);
            //   return sortedPosts[this.count];
            // }
        },
        async mounted() {
            this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();
        },
    };
</script>

<style>
    @import "../../assets/styles/individual-deposits.css";
</style>