<template>
    <div class="deposits" id="posts">
        <div class="banner-container" v-for="item in getPostsById" :key="item.id">
            <div class="banner-content">
                <div class="post-container">
                    <div>
                        <h1 class="post-title" :sq="item.meta._sq_post_title" :en="item.meta._en_post_title" :sr="item.meta._sr_post_title"></h1>
                        <p class="information" v-html="item.meta._sq_post_content" v-show="currentLanguage == 'sq'"></p>
                        <p class="information" v-html="item.meta._en_post_content" v-show="currentLanguage == 'en'"></p>
                        <p class="information" v-html="item.meta._sr_post_content" v-show="currentLanguage == 'sr'"></p>
                    </div>                    
                </div>

            </div>
            <div class="banner-image" id="postimg">
                <img :src="getPostMedia(item.featured_media)" alt="">
            </div>
        </div>
     </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        name: 'PostsList',
        computed: {
            ...mapGetters(["getPosts", "getMedia", "getMedias"]),
            getPostsById() {
                return this.getPosts.filter(x => x.id == this.$route.params.id)
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        methods: {
            ...mapActions(["fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
        },        
        mounted() {
            this.fetchPosts()
            externalScript.reloadLanguage();
        }
    }
</script>

<style>
    #postimg img {
        border-radius: 15px;
        box-shadow: 3px 3px 10px rgb(0 0 0 / 25%);
        max-width: 400px;
        height: auto;
    }
    #posts .banner-container {
        display: flex;
        justify-content: flex-start;
        background-color: #f2ebff;
        height: 100%;
        padding: 80px;
        gap: 3rem;
    }
    
    .post-title {
        padding-bottom: 30px;
        font-size: 40px;
    }
    .post-container {
        padding-left: 12%;

    }
    .information {
        font-size: 18px !important;
    }
    .information p {
        padding-top: 15px;
    }
    @import "../../assets/styles/individual-deposits.css";
</style>

<style scoped>
    .banner-title{
        margin: 5%;
    }
</style>