import axios from "axios";

const state = {
  categories: [],
};

const actions = {
  async fetchCategories({ commit }) {
    const data = await axios.get("/wp/v2/categories?per_page=100");
    if (data.data.length) commit("updateCategories", data.data);
  },
};

const getters = {
  getCategories: (state) => state.categories,
};

const mutations = {
  updateCategories: (state, latest) => (state.categories = latest),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
