<template>
    <section class="mt-100 pxp-no-hero" style="padding-bottom: 25px !important; margin: 48px auto;">
        <div v-for="(item,index) in getAboutUsPosts" :key="index" class="faq-container">
            <div class="wrapper" v-show="currentLanguage == 'sq'">
                <button class="collapsible" v-show="currentLanguage == 'sq'"><span>{{index+1}}. </span>{{item.meta._sq_post_title}}</button>
                <div class="content" v-show="currentLanguage == 'sq'"><p v-html="item.meta._sq_post_content"></p></div>
            </div>
            <div class="wrapper" v-show="currentLanguage == 'en'">
                <button class="collapsible" v-show="currentLanguage == 'en'"><span>{{index+1}}. </span>{{item.meta._en_post_title}}</button>
                <div class="content" v-show="currentLanguage == 'en'"><p v-html="item.meta._en_post_content"></p></div>
            </div>
            <div class="wrapper" v-show="currentLanguage == 'sr'">
                <button class="collapsible" v-show="currentLanguage == 'sr'"><span>{{index+1}}. </span>{{item.meta._sr_post_title}}</button>
                <div class="content" v-show="currentLanguage == 'sr'"><p v-html="item.meta._sr_post_content"></p></div>
            </div>
        </div>
    </section>
</template>

<script>
    import { externalScript } from '../../assets/external';
    import { mapActions, mapGetters } from "vuex";

    export default {
        data() {
            return {
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getAboutUs() {
                return this.getCategories.find(x => x.slug === 'faq')
            },
            getAboutUsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        async mounted() {
            setTimeout(function () {
                externalScript.collapsibile();
            }, 1000);
            await this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();

        },
    };
</script>

<style scoped>
    .collapsible {
        background-color: #5f4393;
        color: white;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 18px;
    }

        .collapsible:hover {
            background-color: rgba(240, 232, 255, 0.5);
            color: black;
        }

    .wrapper {
        margin-top: 15px !important;
        width: 60%;
        margin-left: 20%;
    }

    .content {
        padding: 0 18px;
        max-height: 0;
        height: auto;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        background-color: rgb(240, 232, 255);
    }

        .content p {
            margin-top: 10px;
            padding-bottom: 10px;
            font-weight: 100;
            font-size: 18px;
        }

    @media only screen and (max-width: 600px) {
        .wrapper {
            width: 80%;
            margin-left: 10%;
        }

        .collapsible {
            font-size: 16px;
        }

        .content p {
            margin-top: 0px;
            font-size: 14px !important;
        }
    }
</style>