import axios from "axios";

const state = {
  medias: [],
  media: {},
};

const getters = {
  getMedia: (state) => state.media,
  getMedias: (state) => state.medias,
};

const actions = {
  async fetchMedia({ commit }, id) {
    try {
      const data = await axios.get(`/wp/v2/media?${id}?per_page=100`);
      commit("updateMedia", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchMedias({ commit }) {
    try {
        const data = await axios.get(`/wp/v2/media?per_page=100`);
      commit("updateMedias", data.data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  updateMedia: (state, latest) => (state.media = latest),
  updateMedias: (state, latest) => (state.medias = latest),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
