<template>
    <div class="deposits">
        <div v-for="item in getBankPricingPosts" :key="item.id">
            <div class="banner-container bank-pricing-container">
                <div class="banner-content" id="bank-pricing">
                    <div class="banner-title">
                        <h1 sq="Lista SSI" en="SSI List" sr="Lista SSI" ></h1>
                    </div>
                </div>
            </div>
            <div class="deposits-container">
                <div class="file">
                    <br />
                    <div class="buttoni" v-show="currentLanguage == 'sq'" v-html="item.meta._sq_post_content"></div>
                    <div class="buttoni" v-show="currentLanguage == 'en'" v-html="item.meta._en_post_content"></div>
                    <div class="buttoni" v-show="currentLanguage == 'sr'" v-html="item.meta._sr_post_content"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {

            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getBankPricing() {
                return this.getCategories.find(x => x.slug === 'listassi')
            },
            getBankPricingPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getBankPricing.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        async mounted() {
            this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();
           setTimeout(()=> externalScript.reloadLanguage(), 300);
        },
    };
</script>

<style>
    .deposits-container {
        height: 30vh;
    }

        .deposits-container .file {
            padding-left: 13%;
            padding-top: 5%;
            height: 40vh !important;
        }

    #bank-pricing {
        justify-content: space-around;
    }

    .file a {
        background-color: #5F4393;
        border: none;
        border-radius: 5px;
        color: white;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        width: 150px !important;
        text-align: center;
        text-decoration: none;
        letter-spacing: 0em;
        text-align: left;
        padding: 15px;
    }

    .buttoni object {
        display: none;
    }

    @import "../../assets/styles/individual-deposits.css";
</style>