<template>
    <div class="online-application">
        <!--<div class="online-application-container">
            <div class="banner-container">
                <div class="banner-content">
                    <div class="banner-title">
                        <h1>Depozita Klasike</h1>
                    </div>
                    <div class="banner-image">
                        <img style="height: 100%; width:350px; object-fit:cover; border-radius: 0 0 0 77px;margin-left:64px" src="../../assets/images/depozita-klasike.jpg" />

                    </div>
                </div>
            </div>
        </div> 
        <div class="DepositeContainer">-->
            <!--<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:25px;font-family:"Calibri",sans-serif;'><strong>Depozita Klasike&nbsp;</strong></p>-->
            <!--<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>Ju mund t&euml; p&euml;rfitoni edhe m&euml; shum&euml; t&euml; ardhura p&euml;rmes mjeteve tuaja. Afatizoni mjetet tuaja n&euml; PriBank p&euml;rmes Depozit&euml;s Klasike p&euml;r nj&euml; periudhe t&euml; caktuar kohore.</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>Maturiteti i Depozit&euml;s Klasike mund t&euml; jet&euml; nga 6 deri n&euml; 60 muaj.</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>Norma e interesit dallon nga maturiteti i p&euml;rzgjedhur nga ju p&euml;r afatizimin e mjeteve.</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>P&euml;r normat e interesit shihni &ccedil;mimoren e bank&euml;s </p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>Mund t&euml; aplikoni edhe online p&euml;r afatizimin e mjeteve tuaja apo edhe duke vizituar nj&euml;r&euml;n nga deg&euml;t e PriBank.</p>
        </div>-->
    </div>
</template>

<style>
    .DepositeContainer {
        padding-left: 15%;
        padding-right: 10%;
        min-height: 60vh;
    }

        .DepositeContainer p {
            font-size: 20px !important;
        }
    @import "../../assets/styles/complaints-and-suggestions.css";
</style>

<style scoped>
    @media only screen and (max-width: 600px) {
        .DepositeContainer {
            padding: 12% !important;
        }
    }
</style>