<template>
	<div>
		<div v-for="item in getPosts" :key="item.id" class="post-unit">
			<div class="title-container">
				<h3>{{item.meta._sq_post_title}}</h3>
				<!--<h3>{{item.meta._en_post_title}}</h3>-->
				<!--<h3>{{item.meta._sr_post_title}}</h3>-->
				<small>{{readableDate(item.date)}}</small>
			</div>
			<div v-html="content"></div>
			<div v-html="item.meta._en_post_content"></div>
			<!--<p v-html="item.meta._en_post_content"></p>
			<p v-html="item.meta._sr_post_content"></p>-->   
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { externalScript } from '@/assets/external';

	export default {
		data() {
			return {
				content: ''
			}
		},
		methods: {
			...mapActions(['fetchPosts', 'fetchStructure']),
			readableDate(i) {
				return externalScript.formatDate(i)
			},
			async getSanitized(content) {

				if (!/\[contact-form-7.+?\]/.test(content))
					return;
				let temp = content.match(`contact-form-7 id=".+?"`)[0].split('id="')[1].replace(`"`, "");
				await this.fetchStructure(temp);
				let structure = this.getStructure;
				let tempFormElements = {};
				for (let i = 0; i < structure.length; i++)
					if (tempFormElements[structure[i].field] == null)
						tempFormElements[structure[i].field] = {
							field: structure[i].field,
							rules: [structure[i].rule]
						};
					else
						tempFormElements[structure[i].field].rules.push(structure[i].rule)
				let formContent = `<form action ='https://cms.pribank-ks.com/wp-json/contact-form-7/v1/contact-forms/25/feedback' method='post' enctype='multipart/form-data' onsubmit='return customSubmit(event)'>`;
				for (const property in tempFormElements) {
					if (tempFormElements[property].rules.includes('file'))
						formContent += `<input name="${property}" type="file"/>`;
					else
						formContent += `<input name="${property}" />`;
				}
				console.log(tempFormElements)
				formContent += `<input type='submit'/></form>`;
				this.content = content.replace(/\[contact-form-7.+?\]/, formContent);
			},
			formSubmission(event) {
				console.log(1)
				event.preventDefault();

				//const formElement = document.querySelector("form");
				//const formData = new FormData(formElement);
				//const request = new XMLHttpRequest();
				//request.open("POST", formElement.action);
				//formData.append("fullname", "asdasd");
				//formData.append("email", "test@gmail.com");
				//formData.append("subject", "asdasd");
				//request.send(formData);

				const formElement = document.querySelector("form"),
					{ action, method } = formElement,
					body = new FormData(formElement);

				fetch(action, {
					method,
					body
				})
					.then((response) => response.json())
					.then((response) => {
						console.log(response);
						// Determine if the submission is not valid
						// Handle the happy path
					})
					.catch(() => {
						// Handle the case when there's a problem with the request
					});
			}

		},
		computed: mapGetters(['getPosts', 'getStructure']),
		async mounted() {
			window.customSubmit = event => this.formSubmission(event);
			await this.fetchPosts(this.$route.params.id);
			await this.getSanitized(this.getPosts[0].meta._sq_post_content);
		}
	}
</script>

<style scoped>
	.title-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.post-unit {
		border: 1px solid red;
		padding: 0 1em;
		border-radius: 0.66em;
	}
</style>