<template>
    <div class="organogram-container">
        <div class="banner-container">
            <div class="banner-content">
                <div class="banner-title">
                    <h1>Struktura Organizative</h1>
                    <p>
                        Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem
                        ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor.
                    </p>
                </div>
                <div class="banner-image">
                    <img src="../../assets/images/1-Katatheseis.png" />
                </div>
            </div>
        </div>
        <h1>Struktura Organizative</h1>
        <h3>AAAA</h3>
        <div class="img-wrapper testues">
            <a class="p-2" href="/img/PriBank-Organogrami.8472c03e.png" target="_blank"><img src="../../assets/images/PriBank-Organogrami.png" /></a>
        </div>
    </div>
</template>
<script>
 import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        name: 'PostsList',
        computed: {
            ...mapGetters(["getPosts", "getMedia", "getMedias"]),
            getEnglish() {
                return this.getPosts.filter(x => x.id == 960)
            },
            getAlbanian() {
                return this.getPosts.filter(x => x.id == 958)
            },
            getSerbian() {
                return this.getPosts.filter(x => x.id == 962)
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        methods: {
            ...mapActions(["fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
        },
        mounted() {
            this.fetchPosts()
            externalScript.reloadLanguage();
        }
    }
</script>
<style>
   .img-wrapper {
       text-align: center;
   }

    .img-wrapper > a > img {
        width: 70%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    @import "../../assets/styles/individual-online-application.css";
</style>

<style scoped>
   .organogram-container .banner-container {
        display: flex;
        justify-content: center;
        background-color: #f2ebff;
        height: 358px;
    }
   .organogram-container .banner-container .banner-content {
       max-width: 1144px;
       width: calc(100% - 100px);
       display: flex;
   }
   .organogram-container .banner-container .banner-content .banner-title {
       display: flex;
       flex-direction: column;
       justify-content: center;
   }
   .organogram-container .banner-container .banner-content .banner-title h1 {
       font-size: 42px;
       font-weight: 300;
       line-height: 54px;
       text-align: left;
       padding-top: 16px;
   }
   .organogram-container .banner-container .banner-content .banner-title p {
       padding-top: 16px;
   }
   .organogram-container h1 {
       font-size: 42px;
       font-weight: 300;
       line-height: 54px;
       text-align: center;
       padding-top: 100px;
   }   
   
</style>