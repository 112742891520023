<template>
    <div class="online-application deposite-container">
        <div class="online-application-container deposite-post-container">
            <div class="banner-container post-container">
                <div class="banner-content post-content">
                    <div class="banner-title post-title">
                        <h1>Depozita Avans</h1>                        
                    </div>
                    <div class="banner-image post-image">
                        <img style="height: 100%; border-radius: 0 0 0 77px;margin-left:64px" src="../../assets/images/depozita-avans.jpg" />
                    </div>
                </div>
            </div>
        </div>
        <div class="DepositeContainer">
            <!--<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><strong>Depozita Avans&nbsp;</strong></p>-->
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>Ju mund t&euml; realizoni d&euml;shirat tuaja me interesin e p&euml;rfituar nga Depozita Avans, pa pasur nevoj&euml; t&euml; pritni dit&euml;n e fundit t&euml; maturitetit t&euml; afatizimit t&euml; mjeteve tuaja!&nbsp;</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>Depozita Avans ju mund&euml;son afatizimin e mjeteve tuaja p&euml;r nj&euml; periudh&euml; t&euml; caktuar kohore mir&euml;po interesi i p&euml;rfituar var&euml;sisht nga maturiteti i p&euml;rzgjedhur nga ju, kreditohet menj&euml;her&euml; n&euml; llogarin&euml; tuaj, n&euml; dit&euml;n e hapjes s&euml; llogaris&euml; p&euml;r afatizim!&nbsp;</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>Depozita Avans, ofron mund&euml;sin&euml; e maturitetit nga 12 deri n&euml; 36 muaj.</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>P&euml;r m&euml; shum&euml; detaje, vizitoni nj&euml;r&euml;n nga deg&euml;t e PriBank apo edhe mund t&euml; aplikoni edhe online&nbsp;</p>
        </div>
    </div>
</template>

<style>
    .DepositeContainer {
        margin-top: 3.2rem !important;
        padding-left: 12%;
        padding-right: 12%;
    }
    .online-application-container .banner-container .banner-content {
        width:77%;
    }

    @import "../../assets/styles/complaints-and-suggestions.css";
</style>

<style scoped>
    .post-image img {
        display: block;
    }
    @media only screen and (max-width: 600px)  {
        .DepositeContainer {
            padding: 12% !important;
        }

    }
</style>