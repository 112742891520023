<template>
    <div class="accounts">
        <div class="banner-container">
            <div class="banner-content">
                <div v-for="item in getClientsPosts" :key="item.id" class="card" :class="card == item.id ? 'active-card' : ''" @click="changeCard(item.id)">
                    <span class="card-title">
                        <h1 v-if="item.name.includes('Depozitat')">
                            <span sq="Depozitat" en="Deposits" sr="Depoziti"></span>
                        </h1>
                        <h1 v-if="item.name.includes('Transfere dhe pagesa')">
                            <span sq="Transfere dhe pagesa" en="Transfers and Payments" sr="Transferi i plaćanja"></span>
                        </h1>
                        <h1 v-if="item.name.includes('Produktet dhe shërbimet bankare')">
                            <span sq="Produktet dhe shërbimet bankare" en="Banking Products and Services" sr="Bankarski proizvodi i usluge"></span>
                        </h1>
                        <h1 v-if="item.name.includes('Llogaritë')">
                            <span sq="Llogaritë" en="Accounts" sr="Računi"></span>
                        </h1>
                        <h1 v-if="item.name.includes('Kreditë')">
                            <span sq="Kreditë" en="Loans" sr="Krediti"></span>
                        </h1>
                    </span>
                </div>
            </div>
        </div>
        <div v-if="card == 9" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getAccounts[0].meta._sq_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getAccounts[0].meta._en_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getAccounts[0].meta._sr_post_content"></span>
            </div>
        </div>
        <div v-if="card == 7" style="margin: 0px 15% 10% 15%" class="accounts-body">

            <div v-show="currentLanguage == 'sq'">
                <span v-html="getTransfers[0].meta._sq_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getTransfers[0].meta._en_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getTransfers[0].meta._sr_post_content"></span>
            </div>
        </div>
        <div v-if="card == 8" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getService[0].meta._sq_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getService[0].meta._en_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getService[0].meta._sr_post_content"></span>
            </div>
        </div>
        <div v-if="card == 6" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getDeposits[0].meta._sq_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getDeposits[0].meta._en_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getDeposits[0].meta._sr_post_content"></span>
            </div>
        </div>
        <div v-if="card == 52" style="margin: 0px 15% 10% 15%" class="accounts-body">
            <div v-show="currentLanguage == 'sq'">
                <span v-html="getKredit[0].meta._sq_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'en'">
                <span v-html="getKredit[0].meta._en_post_content"></span>
            </div>
            <div v-show="currentLanguage == 'sr'">
                <span v-html="getKredit[0].meta._sr_post_content"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                card: 1,
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            changeCard(e) {
                console.log(e);
                this.card = e;
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getClients() {
                return this.getCategories.find(x => x.slug === 'clients')
            },
            getClientsPosts() {
                return this.getCategories.filter(x => x.parent === this.getClients.id)
            },
            getAccounts() {
                return this.sortedPosts.filter(x => x.id == 729);
            },
            getTransfers() {
                return this.sortedPosts.filter(x => x.id == 732);
            },
            getDeposits() {
                return this.sortedPosts.filter(x => x.id == 734);
            },
            getKredit() {
                return this.sortedPosts.filter(x => x.id == 736);
            },
            getService() {
                return this.sortedPosts.filter(x => x.id == 738);
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        async mounted() {
            this.card = this.getClientsPosts[0].id
            this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();
            setTimeout(() => externalScript.reloadLanguage());
            console.log(this.getAccounts[0].meta._sq_post_content)
        },
    };
</script>

<style>
    @import "../../assets/styles/individual-accounts.css";

    .accounts-body .title {
        color: #5f4393;
        font-size: 20px;
    }
</style>