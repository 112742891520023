import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import Categories from "./modules/categories";
import Pages from "./modules/pages";
import Forms from "./modules/forms";
import Posts from "./modules/posts";
import Medias from "./modules/medias";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    Categories,
    Pages,
    Posts,
    Forms,
    Medias,
  },
});
