<template>
	<div>
		<Header></Header>
		<router-view />
		<Footer></Footer>
	</div>
</template>

<script>
import Header from "./components/Shared/Header.vue";
import Footer from "./components/Shared/Footer.vue";
export default {
	components: {
		Header,
		Footer
	},
}
</script>

<style>
	html * {
		font-family: 'Lato', sans-serif;
		margin: 0;
	}
	body{
		margin: 0;
	}
</style>