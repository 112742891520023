<template>
    <div class="data-protection">
        <div v-for="item in getDataProtectionPosts" :key="item.id">
            <div class="banner-container">
                <div class="banner-content" id="data-protection">
                    <div class="banner-title">
                        <h1 :sq="item.meta._sq_post_title" :en="item.meta._en_post_title" :sr="item.meta._sr_post_title"></h1>
                    </div>
                </div>
            </div>           
            <div class="data-protection-content" style="padding-top: 2%; padding-bottom: 5%">
                <div v-show="currentLanguage == 'sq'" v-html="item.meta._sq_post_content"></div>
                <div v-show="currentLanguage == 'en'" v-html="item.meta._en_post_content"></div>
                <div v-show="currentLanguage == 'sr'" v-html="item.meta._sr_post_content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            }            
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getDataProtectionCategory() {
                return this.getCategories.find(x => x.slug === 'data-protection')
            },
            getDataProtectionPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getDataProtectionCategory.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
            // showPost(){
            //   this.getPostMedia();
            //   let sortedPosts = [...this.getPosts].sort((a, b) => a.id - b.id);
            //   return sortedPosts[this.count];
            // }
        },
        async mounted() {
            this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();
            setTimeout(()=> externalScript.reloadLanguage(), 100);
        },
    };
</script>

<style>
    #data-protection{
        justify-content: space-around;
    }
    @import "../../assets/styles/individual-data-protection.css";

    
</style>
<style>
    .data-protection-content  strong {
        color: #5f4393 !important;
        padding-top: 10px;
    }
</style>