<template>
    <div class="cards">
        <div class="banner-container">
            <div class="banner-content">
                <div class="banner-title">
                    <h1>Kartelat bankare</h1>                    
                </div>
                <div class="banner-image">
                    <img src="../../assets/images/1-Katatheseis.png" />
                </div>
            </div>
        </div>
        <div class="cards-container">
            <div class="grid">
                <article>
                    <img src="https://picsum.photos/600/400?image=1083" alt="Sample photo">
                    <div class="text">
                        <h3>Seamlessly visualize quality</h3>
                        <p>Collaboratively administrate empowered markets via plug-and-play networks.</p>
                        <a href="https://codepen.io/collection/XdWJOQ/" class="btn btn-primary btn-block">Here's why</a>
                    </div>
                </article>
                <article>
                    <img src="https://picsum.photos/600/400?image=1063" alt="Sample photo">
                    <div class="text">
                        <h3>Completely Synergize</h3>
                        <p>Dramatically engage seamlessly visualize quality intellectual capital without superior collaboration
                            and idea-sharing.</p>
                        <a href="https://codepen.io/collection/XdWJOQ/" class="btn btn-primary btn-block">Here's how</a>
                    </div>
                </article>
                <article>
                    <img src="https://picsum.photos/600/400?image=1056" alt="Sample photo">
                    <div class="text">
                        <h3>Dynamically Procrastinate</h3>
                        <p>Completely synergize resource taxing relationships via premier niche markets.</p>
                        <a href="https://codepen.io/collection/XdWJOQ/" class="btn btn-primary btn-block">Read more</a>
                    </div>
                </article>
                <article>
                    <img src="https://picsum.photos/600/400?image=1050" alt="Sample photo">
                    <div class="text">
                        <h3>Best in class</h3>
                        <p>Imagine jumping into that boat, and just letting it sail wherever the wind takes you...</p>
                        <a href="https://codepen.io/collection/XdWJOQ/" class="btn btn-primary btn-block">Just do it...</a>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
        }
    },
    methods: {
        ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
        getPostMedia(id) {
            let media = this.getMedias.find(x => x.id === id);
            return media?.source_url;
        },
    },
    computed: {
        ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
        getAboutUs() {
            return this.getCategories.find(x => x.slug === 'about-us')
        },
        getAboutUsPosts() {
            return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
        },
        sortedPosts() {
            return [...this.getPosts].sort((a, b) => a.id - b.id)
        },
        // showPost(){
        //   this.getPostMedia();
        //   let sortedPosts = [...this.getPosts].sort((a, b) => a.id - b.id);
        //   return sortedPosts[this.count];
        // }
    },
    async mounted() {
        this.fetchCategories();
        await this.fetchPosts();
        await this.fetchMedias();
    },
};
</script>

<style>
@import "../../assets/styles/cards.css";
</style>