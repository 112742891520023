function formatDate(sqlDate, format) {
	if (!sqlDate)
		return null;
	let input = new Date(sqlDate);
	let dd = String(input.getDate()).padStart(2, '0');
	let mm = String(input.getMonth() + 1).padStart(2, '0');
	let yyyy = input.getFullYear();
	let hh = String(input.getHours()).padStart(2, '0');
	let m = String(input.getMinutes()).padStart(2, '0');
	let s = String(input.getSeconds()).padStart(2, '0');
	return format.replace('dd', dd).replace('MM', mm).replace('yyyy', yyyy).replace('HH', hh).replace('mm', m).replace('ss', s);
}

function collaps() {
	var coll = document.getElementsByClassName("collapsible");
	var i;

	for (i = 0; i < coll.length; i++) {
		coll[i].addEventListener("click", function () {
			this.classList.toggle("active");
			var content = this.nextElementSibling;
			if (content.style.maxHeight) {
				content.style.maxHeight = null;
			} else {
				content.style.maxHeight = content.scrollHeight + "px";
			}
		});
	}
}

function openMenu() {
	var x = document.getElementById("myLinks");
	if (x.style.display === "block") {
		x.style.display = "none";
	} else {
		x.style.display = "block";
	}
}

function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	return parts.length === 2 ? parts.pop().split(';').shift() : null;
}

function setCookie(name, value) {
	const date = new Date();
	date.setTime(date.getTime() + 604800000);
	document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
	return value;
}

function ReloadLanguage() {
	var lang = getCookie("lang");

	if (lang == null) {
		lang = setCookie("lang", "sq-al");
	}	

	lang = getCookie("lang");

	[...document.querySelectorAll("[sq]")].forEach(item =>
		item.innerHTML = item.getAttribute(lang)
	);
}

function popup(title, content, customEvent) {
	if (content[0] !== '<')
		content = `<p>${content}</p>`;
	let div = document.createElement("div");
	div.className = 'popup';
	div.innerHTML = `
		<div class="popup-content">
			<div class='header menu-opened d-flex'>
				<h3 class='m-0'>${title}</h3>
				<div class="menu-icon ml-auto cursor-pointer"></div>
			</div>
			<div class='body'>
				${content}
			</div>
			<div class='footer'>
				<button id="popup-close" role="button" class="butoni">Mbyll</button>
			</div>
		</div>`;
	document.body.appendChild(div);
	setTimeout(() => {
		document.querySelector(".popup").classList.add('shown');
		document.getElementById("popup-close").addEventListener("click", function () {
			document.querySelector(".popup").classList.remove('shown');
			setTimeout(() => document.querySelector(".popup").remove(), 500);
			if (customEvent != null)
				customEvent();
		})
	}, 10);
}


function FormatCurrency(inputVal) {
	var formatNumber = n => n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	if (inputVal.includes(".")) {
		const decimalPos = inputVal.indexOf(".");
		let leftSide = inputVal.substring(0, decimalPos);
		let rightSide = inputVal.substring(decimalPos);

		leftSide = formatNumber(leftSide);
		rightSide = formatNumber(rightSide);
		rightSide = rightSide.substring(0, 2);

		inputVal = `${leftSide}.${rightSide} €`;
	} else {
		inputVal = formatNumber(inputVal);
		inputVal = `${inputVal}.00 €`;
	}

	return inputVal;
}

export const externalScript = {
	formatDate: d => formatDate(d, 'dd.MM.yyyy HH:mm'),
	collapsibile: () => collaps(),
	popup: (t, c, e) => popup(t, c, e),
	openmenu: () => openMenu(),
	reloadLanguage: () => ReloadLanguage(),
	formatCurrency: () => FormatCurrency(),
}