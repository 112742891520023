<template>
    <div class="products-services">
        <div class="banner-container">
            <div class="banner-content">
                <div class="banner-title">
                    <h1>Produktet dhe shërbimet bankare</h1>                   
                </div>
                <div class="banner-image">
                    <img src="../../assets/images/1-Katatheseis.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
        }
    },
    methods: {
        ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
        getPostMedia(id) {
            let media = this.getMedias.find(x => x.id === id);
            return media?.source_url;
        },
    },
    computed: {
        ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
        getAboutUs() {
            return this.getCategories.find(x => x.slug === 'about-us')
        },
        getAboutUsPosts() {
            return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
        },
        sortedPosts() {
            return [...this.getPosts].sort((a, b) => a.id - b.id)
        },
    },
    async mounted() {
        this.fetchCategories();
        await this.fetchPosts();
        await this.fetchMedias();
    },
};
</script>

<style>
@import "../../assets/styles/individual-products-services.css";
</style>