<template>
    <div class="online-application">
        <div class="online-application-container">

            <div class="formbold-main-wrapper">
                <div class="formbold-form-wrapper">
                    <div class="title">
                        <h1 sq="Aplikim për Kredi Individuale" en="Application for Individual Loan" sr="Prijava za Individualni Kredit"></h1>
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Emri" en="First Name" sr="Ime"></label>
                        <input type="text" v-model="formData.firstName" placeholder="Emri" class="formbold-form-input" required />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Mbiemri" en="Last Name" sr="Prezime"></label>
                        <input type="text" v-model="formData.lastName" placeholder="Mbiemri" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Adresa" en="Address" sr="Adresa"></label>
                        <input type="text" v-model="formData.address" placeholder="Adresa" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Numri personal" en="Personal Number" sr="Lični broj"></label>
                        <input type="text" maxlength="10" v-model="formData.personalNumber" placeholder="Numri personal" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="phone" class="formbold-form-label" sq="Numri i telefonit" en="Phone Number" sr="Broj telefona"></label>
                        <input type="text" v-model="formData.phoneNumber" placeholder="Numri i telefonit" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="email" class="formbold-form-label" sq="E-mail" en="E-mail" sr="E-pošta"></label>
                        <input type="email" v-model="formData.email" name="email" id="email" placeholder="E-mail" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Emri i punëdhënësit" en="Employer Name" sr="Ime poslodavca"></label>
                        <input type="text" v-model="formData.employerName" placeholder="Emri i punëdhënësit" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Punëdhënësi juaj është" en="Your Employer Is" sr="Vaš poslodavac je"></label>
                        <select placeholder="Punëdhënësi juaj është" class="formbold-form-input" v-model="formData.company">
                            <option value="Institucion shtetëror" selected="selected" sq="Institucion shtetëror" en="State Institution" sr="Državna institucija"></option>
                            <option value="Ndërmarrje Publike" sq="Ndërmarrje Publike" en="Public Enterprise" sr="Javno preduzeće"></option>
                            <option value="Ndërmarrje Private" sq="Ndërmarrje Private" en="Private Enterprise" sr="Privatno preduzeće"></option>
                        </select>
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="A e pranoni pagën në PriBank?" en="Do you accept salary in PriBank?" sr="Da li prihvatate platu u PriBank?"></label>
                        <select placeholder="A e pranoni pagën në PriBank?" class="formbold-form-input" v-model="formData.salaryQuestion">
                            <option value="Po" selected="selected" sq="Po" en="Yes" sr="Da"></option>
                            <option value="Jo" sq="Jo" en="No" sr="Ne"></option>
                        </select>
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Shuma e kredisë" en="Loan Amount" sr="Iznos kredita"></label>
                        <input type="text" maxlength="10" v-model="formData.loanAmmount" placeholder="Shuma e kredisë" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Dega ku dëshironi të aplikoni" en="Branch where you want to apply" sr="Filijala na kojoj želite da aplicirate"></label>
                        <select placeholder="Dega ku deshironi te aplikoni" class="formbold-form-input" v-model="formData.branch">
                            <option value="Prishtinë" selected="selected" sq="Prishtinë" en="Prishtinë" sr="Priština"></option>
                            <option value="Prishtinë 2" selected="selected" sq="Prishtinë - Dega 2" en="Prishtinë - Branch 2" sr="Priština - Filijala 2"></option>
                            <option value="FKosove" sq="Fushë Kosovë" en="Fushë Kosovë" sr="Fushë Kosovë"></option>
                            <option value="Ferizaj" sq="Ferizaj" en="Ferizaj" sr="Ferizaj"></option>
                            <option value="Prizren" sq="Prizren" en="Prizren" sr="Prizren"></option>
                            <option value="Gjilan" sq="Gjilan" en="Gjilan" sr="Gilan"></option>
                            <option value="Peje" sq="Pejë" en="Peja" sr="Peja"></option>
                            <option value="Gjakovë" sq="Gjakovë" en="Gjakovë" sr="Gjakovë"></option>
                            <option value="Mitrovicë" sq="Mitrovicë" en="Mitrovicë" sr="Mitrovicë"></option>
                            <option value="Drenas" sq="Drenas" en="Drenas" sr="Drenas"></option>
                            <option value="Suharekë" sq="Suharekë" en="Suharekë" sr="Suharekë"></option>
                            <option value="Lipjan" sq="Lipjan" en="Lipjan" sr="Lipjan"></option>
                            <option value="Vushtrri" sq="Vushtrri" en="Vushtrri" sr="Vushtrri"></option>
                            <option value="Prishtinë - Rruga B" sq="Prishtinë - Rruga B" en="Prishtinë - Rruga B" sr="Prishtinë - Rruga B"></option>
                        </select>
                        <br> <br />
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                        <label for="vehicle1" style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;' sq=" Deklarata për përpunim të të dhënave personale" en="Declaration for Processing of Personal Data" sr="Izjava o obradi ličnih podataka"></label><br>
                        <br />
                        <p sq="Të dhënat personale të cilat kërkohen për aplikim online nga PriBank do të përpunohen në pajtim me Ligjin nr. 06/L-082 dhe Rregulloren e Bashkimit Europian 2016/679 (GDRP) dhe këto të dhëna do të përpunohen vetëm për qëllim të realizimit të këtij aplikacioni. Me klikimin e butonit “Pajtohem”, ju pajtoheni se të dhënat personale të lartë cekura të përpunohen nga PriBank për qëllimin e specifikuar, poashtu konfirmoni se këto të dhëna dhe dokumentet mbështetëse të ngarkuara nga ju janë të plota, të sakta dhe të vërteta."
                           en="Personal data required for online application by PriBank will be processed in accordance with Law no. 06/L-082 and Regulation of the European Union 2016/679 (GDRP) and these data will be processed only for the purpose of realizing this application. By clicking the 'I agree' button, you agree that the above personal data will be processed by PriBank for the specified purpose, and you also confirm that this data and the supporting documents uploaded by you are complete, correct and true."
                           sr="Lični podaci potrebni za onlajn prijavu od strane PriBank-a biće obrađeni u skladu sa Zakonom br. 06/L-082 i Uredbe Evropske unije 2016/679 (GDRP) i ovi podaci će se obrađivati samo u svrhu realizacije ove aplikacije. Klikom na dugme „Slažem se“, saglasni ste da će PriBank gore navedene lične podatke obrađivati u navedenu svrhu, a takođe potvrđujete da su ovi podaci i prateća dokumentacija koju ste uneli potpuni, tačni i istiniti."></p>
                        <br />
                        <p>
                            <i sq="Sqarim: Aplikimi përmes faqes sonë të internetit për produktin/shërbimin e kërkuar nuk nënkupton krijimin e marrëdhënie biznesore me PriBank. PriBank rezervon të drejtën që të ju kontaktoj dhe të kërkoj nga ju dokumente shtesë duke u bazuar në Ligjet dhe Rregulloret në fuqi. Vetëm pas kompletimit të të gjitha kërkesave të PriBank për produktin/shërbimin e kërkuar dhe pas nënshkrimit të marrëveshjes specifike ju mund të pranoni produktin/shërbimin e kërkuar."
                               en="Clarification: Applying through our website for the requested product/service does not mean establishing a business relationship with PriBank. PriBank reserves the right to contact you and request additional documents from you based on the Laws and Regulations in force. Only after completing all PriBank requirements for the requested product/service and after signing the specific agreement can you accept the requested product/service."
                               sr="Pojašnjenje: Podnošenje zahteva preko naše veb stranice za traženi proizvod/uslugu ne znači uspostavljanje poslovnog odnosa sa PriBank. PriBank zadržava pravo da vas kontaktira i zatraži dodatnu dokumentaciju na osnovu važećih zakona i propisa. Tek nakon ispunjavanja svih uslova PriBank-a za traženi proizvod/uslugu i nakon potpisivanja konkretnog ugovora možete prihvatiti traženi proizvod/uslugu."></i>
                        </p>
                    </div>
                    <div class="file-upload">
                        <h3 sq="Dokument identifikimi" en="Identification Document" sr="Dokument identifikacije"></h3>
                        <div class="drop_box" id="invoiceUpload1">
                            <header>
                                <h4 sq="Zgjidhni skedarin këtu" en="Select File here" sr="Izaberite fajl ovde"></h4>
                            </header>
                            <p sq="Skedarët e mbështetura: PDF, TEXT, DOC , DOCX" en="Files Supported: PDF, TEXT, DOC, DOCX" sr="Podržani fajlovi: PDF, TEKST, DOC, DOCX"></p>
                            <input type="file" hidden accept=".doc,.docx,.pdf" id="fileID" style="display:none;" @change="onFileChange1">
                            <button class="btn" @click="focusIDFileInput()" sq="Zgjidhni Skedarin" en="Choose File" sr="Izaberite fajl"></button>
                        </div>
                        <div style="display: flex">
                            <p class="required">*</p><p class="note" sq="Vëretje: Dokumentet e lejuara për verifikim janë letërnjoftim ose pasaportë valide." en="Note: Accepted verification documents are valid ID or passport." sr="Napomena: Prihvaćeni verifikacioni dokumenti su važeći lični dokument ili pasoš."></p>
                        </div>
                    </div>
                    <div class="file-upload">
                        <h3 sq="Kontrata e punës" en="Employment Contract" sr="Ugovor o radu"></h3>
                        <div class="drop_box" id="invoiceUpload2">
                            <header>
                                <h4 sq="Zgjidhni skedarin këtu" en="Select File here" sr="Izaberite fajl ovde"></h4>
                            </header>
                            <p sq="Skedarët e mbështetura: PDF, TEXT, DOC , DOCX" en="Files Supported: PDF, TEXT, DOC, DOCX" sr="Podržani fajlovi: PDF, TEKST, DOC, DOCX"></p>
                            <input type="file" hidden accept=".doc,.docx,.pdf" id="fileContract" style="display:none;" @change="onFileChange2">
                            <button class="btn" @click="focusContractFileInput()" sq="Zgjidhni Skedarin" en="Choose File" sr="Izaberite fajl"></button>
                        </div>
                        <div style="display: flex">
                            <p class="required">*</p><p class="note" sq="" en="Note: " sr="Napomena: "></p>
                        </div>
                    </div>
                    <div class="file-upload">
                        <h3 sq="Faturë publike" en="Public Invoice" sr="Javni račun"></h3>
                        <div class="drop_box" id="invoiceUpload3">
                            <header>
                                <h4 sq="Zgjidhni skedarin këtu" en="Select File here" sr="Izaberite fajl ovde"></h4>
                            </header>
                            <p sq="Skedarët e mbështetura: PDF, TEXT, DOC , DOCX" en="Files Supported: PDF, TEXT, DOC, DOCX" sr="Podržani fajlovi: PDF, TEKST, DOC, DOCX"></p>
                            <input type="file" hidden accept=".doc,.docx,.pdf" id="invoiceID" style="display:none;" @change="onFileChange3">
                            <button class="btn" @click="focusInvoiceFileInput()" sq="Zgjidhni Skedarin" en="Choose File" sr="Izaberite fajl"></button>
                        </div>
                        <div style="display: flex">
                            <p class="required">*</p><p class="note" sq="Vëretje: Fatura nuk duhet të jetë më e vjetër se 6 muaj." en="Note: The invoice should not be older than 6 months." sr="Napomena: Račun ne sme biti stariji od 6 meseci."></p>
                        </div>
                    </div>
                    <div>
                        <button class="formbold-btn" @click="insertFormData()" sq="Dërgo" en="Send" sr="Pošalji"></button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                formData: {
                    firstName: '',
                    lastName: '',
                    address: '',
                    personalNumber: '',
                    phoneNumber: '',
                    email: '',
                    employerName: '',
                    company: '',
                    salaryQuestion: '',
                    loanAmmount: '',
                    branch: '',
                    idFile: '',
                    contractFile: '',
                    billFile:''
                },
                formID: 0
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias", "insertComplaintSuggestionForm"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            async insertFormData() {
                if (!this.controlEmail(this.formData.email)) {
                    externalScript.popup(" ", "Email juaj është gabim!")
                    return;
                }
                if (this.formData.firstName == ""
                    || this.formData.lastName == ""
                    || this.formData.address == ""
                    || this.formData.personalNumber == ""
                    || this.formData.phoneNumber == ""
                    || this.formData.email == ""
                    || this.formData.employerName == ""
                    || this.formData.company == ""
                    || this.formData.salaryQuestion == ""
                    || this.formData.loanAmmount == ""
                    || this.formData.applicationBranch == ""
                    || this.formData.idFile == ""
                    || this.formData.contractFile == ""
                    || this.formData.billFile == ""
                ){
                    externalScript.popup("Gabim", "Të gjitha fushat duhet të plotësohen.")
                    return;
                }

                var bodyFormData = new FormData();
                bodyFormData.append('firstName', this.formData.firstName);
                bodyFormData.append('lastName', this.formData.lastName);
                bodyFormData.append('address', this.formData.address);
                bodyFormData.append('personalNumber', this.formData.personalNumber);
                bodyFormData.append('phoneNumber', this.formData.phoneNumber);
                bodyFormData.append('email', this.formData.email);
                bodyFormData.append('employerName', this.formData.employerName);
                bodyFormData.append('company', this.formData.company);
                bodyFormData.append('salaryQuestion', this.formData.salaryQuestion);
                bodyFormData.append('loanAmmount', this.formData.loanAmmount);
                bodyFormData.append('applicationBranch', this.formData.branch);
                bodyFormData.append('idFile', this.formData.idFile);
                bodyFormData.append('contractFile', this.formData.contractFile);
                bodyFormData.append('billFile', this.formData.billFile);

                switch (this.formData.branch) {
                    case 'Ferizaj':
                        this.formID = 389;
                        break;
                    case 'FKosove':
                        this.formID = 692;
                        break;
                    case 'Prishtinë 2':
                        this.formID = 896;
                        break;
                    case 'Prizren':
                        this.formID = 669;
                        break;
                    case 'Gjilan':
                        this.formID = 894;
                        break;
                    case 'Peje':
                        this.formID = 895;
                        break;
                    case 'Drenas':
                        this.formID = 1110;
                        break;
                    case 'Gjakovë':
                        this.formID = 1108;
                        break;
                    case 'Mitrovicë':
                        this.formID = 1109;
                        break;
                    case 'Suharekë':
                        this.formID = 1142;
                        break;
                    case 'Lipjan':
                        this.formID = 1155;
                        break;
                    case 'Vushtrri':
                        this.formID = 1175;
                        break;
                    case 'Prishtinë - Rruga B':
                        this.formID = 1251;
                        break;
                    default:
                        this.formID = 6;
                        break;
                }
                
                bodyFormData.append('_wpcf7_unit_tag', this.formID);
                
                await this.insertComplaintSuggestionForm({
                    id: this.formID,
                    formData: bodyFormData,
                    successCallback: () => {
                        this.clearFormData();
                        externalScript.popup(
                            " ",
                            "Aplikimi juaj është kryer me sukses! <br /> Për detaje të mëtutjeshme do të kontaktoheni nga Këshilltarët tanë për klientë!"
                        )
                    }
                });
            },
            clearFormData() {
                this.formData.firstName = '';
                this.formData.lastName = '';
                this.formData.address = '';
                this.formData.personalNumber = '';
                this.formData.phoneNumber = '';
                this.formData.email = '';
                this.formData.employerName = '';
                this.formData.company = '';
                this.formData.salaryQuestion = '';
                this.formData.loanAmmount = '';
                this.formData.branch = '';
                this.formData.idFile = '';
                this.formData.contractFile = '';
                this.formData.billFile = '';
            },
            focusIDFileInput() {
                document.getElementById("fileID").click()
            },
            focusContractFileInput() {
                document.getElementById("fileContract").click()
            },
            focusInvoiceFileInput() {
                document.getElementById("invoiceID").click()
            },
            onFileChange1(e) {
                const dropArea = document.getElementById("invoiceUpload1")
                var fileName = e.target.files[0].name;
                this.formData.idFile = e.target.files[0];                
                let filedata = `<p>${fileName}</p>`;
                dropArea.innerHTML = filedata;
            },
            onFileChange2(e) {
                const dropArea = document.getElementById("invoiceUpload2")
                var fileName = e.target.files[0].name;
                this.formData.contractFile = e.target.files[0];
                let filedata = `<p>${fileName}</p>`;
                dropArea.innerHTML = filedata;
            },
            controlEmail(email) {
                const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return emailRegex.test(email);
            },
            onFileChange3(e) {
                const dropArea = document.getElementById("invoiceUpload3")
                var fileName = e.target.files[0].name;
                this.formData.billFile = e.target.files[0];
                let filedata = `<p>${fileName}</p>`;
                dropArea.innerHTML = filedata;
            }            
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getAboutUs() {
                return this.getCategories.find(x => x.slug === 'online-applications')
            },
            getAboutUsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
        },
        mounted() {
            externalScript.reloadLanguage();
        }
    };
</script>

<style>
    @import "../../assets/styles/individual-online-application.css";
</style>