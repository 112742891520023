<template>
    <div class="online-application">
        <div class="online-application-container">
            <div class="banner-container">
                <div class="banner-content" >
                    <div class="banner-title">
                        <h1>Llogaria e kursimit p&euml;r f&euml;mij&euml;</h1>
                    </div>
                    <div class="banner-image">
                        <img  style="height: 100%; border-radius: 0 0 0 77px;margin-left:64px" src="../../assets/images/llogari-kursimi-per-femije.jpg" />
                    </div>
                </div>
            </div>
        </div>
        <div class="DepositeContainer">
            <!--<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'><strong>Llogaria e kursimit p&euml;r f&euml;mij&euml;</strong></p>-->
            <br />
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>Ju mund t&euml; kurseni nga tani p&euml;r t&euml; ardhmen e f&euml;mij&euml;ve tuaj. Kursimet sot mund t&euml; ju sh&euml;rbejn&euml; p&euml;r shkollimin apo realizimin e ndonj&euml; k&euml;rkese tjet&euml;r p&euml;r f&euml;mij&euml;n tuaj n&euml; t&euml; ardhmen!</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>Hapni llogarin&euml; e kursimit p&euml;r f&euml;mij&euml; dhe p&euml;rfitoni interes 0.4% vjetor.</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>Interesi kalkulohet n&euml; baza ditore dhe kreditohet n&euml; dit&euml;n e fundit t&euml; vitit.</p>
            <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>P&euml;r t&euml; hapur llogarin&euml; e kursimit p&euml;r f&euml;mij&euml; duhet t&euml; vizitoni nj&euml;r&euml;n nga deg&euml;t e PriBank dhe dokumentet e nevojshme p&euml;r hapjen e llogaris&euml; jan&euml; :</p>
            <ul style="">
                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>&Ccedil;ertifikata e lindjes s&euml; femiut apo pasaporta</li>
                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>Let&euml;rnjoftimi apo pasaporta e prindit apo kujdestarit ligjor</li>
                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>Fatur&euml; publike (jo me e vjet&euml;r se 6 muaj)</li>
                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:20px;font-family:"Calibri",sans-serif;'>Kujdestari ligjor duhet t&euml; ket&euml; dokumentin, i cili v&euml;rteton se ka t&euml; drejte/leje kujdesi p&euml;r f&euml;miun nga autoritetet p&euml;rkat&euml;se.</li>
            </ul>
        </div>
    </div>
</template>

<style>
    .DepositeContainer {
        padding-left: 12%;
        padding-right: 12%;
        margin-top: 30px;
        
    }
    .online-application-container .banner-container .banner-content {
        justify-content: center !important;
    }

        @import "../../assets/styles/complaints-and-suggestions.css";
</style>

<style scoped>
    @media only screen and (max-width: 600px) {
        .DepositeContainer {
            padding: 12% !important;
            min-height: min-content !important;
        }
    }
</style>