<template>
    <div class="aboutus">
        <div class="banner-container">
            <div class="banner-content">
                <div v-for="item,index in getCustomerCareCategories" :key="index" class="card" :class="card == index ? 'active-card' : ''" @click="changeCard(index)">
                    <span class="card-title" v-if="item.name.includes('Ankesa dhe sugjerime')">
                        <h1 sq="Ankesa dhe sugjerime" en="Complaints and suggestions" sr="Žalbe i sugestije"></h1>
                    </span>

                    <span class="card-title" v-if="item.name.includes('Degët e bankës')">
                        <h1 sq="Degët e bankës" en="Bank branches" sr="Filijale banaka"></h1>
                    </span>

                    <span class="card-title" v-if="item.name.includes('Mbrojtja e të dhënave')">
                        <h1 sq="Mbrojtja e të dhënave" en="Data protection" sr="Zaštita podataka"></h1>
                    </span>

                    <span class="card-title" v-if="item.name.includes('Kushtet e Përgjithshme të Afarizmit Bankar')">
                        <h1 sq="Kushtet e Përgjithshme të Afarizmit Bankar" en="General Conditions of Banking Business" sr="Opšti uslovi bankarskog poslovanja"></h1>
                    </span>

                    <span class="card-title" v-if="item.name.includes('Çmimorja e bankës')">
                        <h1 sq="Çmimorja e bankës" en="Bank pricing" sr="Bankarske cene"></h1>
                    </span>

                    <span class="card-title" v-if="item.name.includes('Pyetje të zakonshme')">
                        <h1 sq="Pyetje të zakonshme" en="FAQ" sr="Uobičajena pitanja"></h1>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Lista SSI')">
                        <h1 sq="Lista SSI" en="Lista SSI" sr="Lista SSI">{{ item.name }}</h1>
                    </span>
                </div>

            </div>
        </div>
        <div>
            <div class="aboutus-container customercare-container" v-if="card == 0">
                <div class="aboutus-content customercare-container">
                    <ListSSI />
                </div>
            </div>
            <div class="aboutus-container customercare-container" v-if="card == 1">
                <div class="aboutus-content customercare-container">
                    <Banks />
                </div>
            </div>
            <div class="aboutus-container customercare-container" v-if="card == 2">

                <div class="aboutus-content customercare-container">
                    <ComplaintsAndSuggestions />
                </div>
            </div>
            <div class="aboutus-container customercare-container" v-if="card == 3">

                <div class="aboutus-content customercare-container">
                    <DataProtection />
                </div>
            </div>
            <div class="aboutus-container customercare-container" v-if="card == 4">

                <div class="aboutus-content customercare-container">
                    <GeneralAfarismConditions />
                </div>
            </div>
            <div class="aboutus-container customercare-container" v-if="card == 5">

                <div class="aboutus-content">
                    <BankPricing />
                </div>
            </div>
            <div class="aboutus-container customercare-container" v-if="card == 6">

                <div class="aboutus-content">
                    <FAQ />
                </div>
            </div>
            <div class="aboutus-container customercare-container" v-if="card == 7">
                <div class="aboutus-content">
                    <Privacy />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import Banks from "@/components/Shared/Locations/~.vue";
    import ComplaintsAndSuggestions from "@/components/Shared/ComplaintsAndSuggestions.vue";
    import GeneralAfarismConditions from "@/components/Shared/GeneralAfarismConditions.vue";
    import DataProtection from "@/components/Shared/DataProtection.vue";
    import BankPricing from "@/components/Shared/BankPricing.vue";
    import Privacy from "@/components/Shared/Privacy.vue";
    import FAQ from "@/components/Shared/FAQ.vue";
import { externalScript } from "../../assets/external";
import ListSSI from "@/components/Shared/ListSSI.vue";

    export default {
        data() {
            return {
                card: 1,
            }
        },
        components: {
            ComplaintsAndSuggestions,
            Banks,
            DataProtection,
            GeneralAfarismConditions,
            BankPricing,
            FAQ,
            Privacy,
            ListSSI
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            changeCard(e) {
                this.card = e;
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getCustomerCare() {
                return this.getCategories.find(x => x.slug === 'customer-care')
            },
            getCustomerCareCategories() {
                return this.getCategories.filter(x => x.parent === this.getCustomerCare?.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
        },
        async mounted() {
            this.card = this.getCustomerCareCategories[0]
            await this.fetchMedias();
            await this.fetchCategories();
            await this.fetchPosts();
           setTimeout(()=> externalScript.reloadLanguage(), 200);
        },
    };
</script>

<style scoped>
    .ceo-post .title {
        padding-bottom: 30px;
    }

    .ceo-post .description p {
        padding: 20px;
        font-size: 18px;
    }

    .ceo-post .description {
        text-align: left;
        padding-left: 10%;
        padding-right: 10%;
    }

    .aboutus .mission-container {
        margin-left: 0;
        padding-top: 75px;
        margin-right: 0;
        padding-right: 0;
        padding-bottom: 75px;
    }

    .aboutus-container {
        width: 100% !important;
        padding: 0px !important;
    }

    .aboutus .aboutus-container .aboutus-content {
        padding-top: 10px;
    }

    @import "../../assets/styles/individual-accounts.css";
</style>