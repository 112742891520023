<template>
    <div>
        <div class="banner-container">
            <div class="banner-content" id="data-protection">
                <div class="banner-title">
                    <h1>Njoftime</h1>
                </div>
            </div>
        </div>
        <div class="annoucement-wrapper">
            <div style="width: 80vw; margin-left: 10%; margin-right: 10%;" >
                <ul class="annoucement-list" v-for="(item,index) in getAboutUsPosts" :key="index">
                    <li class="annoucement-card">
                        <a :href="`https://pribank-ks.com/individual/post/${item.id}`">
                            <div class="image-wrapper">
                                <img :src="getPostMedia(getAboutUsPosts[index]?.featured_media)" />
                                <div class="overlay">
                                    <div class="text">{{item.meta._sq_post_title}}</div>
                                </div>                                
                            </div>
                            <h3>
                                {{item.meta._sq_post_title}}
                            </h3>
                            <p v-html="item.meta._sq_post_content">

                            </p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { externalScript } from '../../assets/external';
    import { mapActions, mapGetters } from "vuex";

    export default {
        data() {
            return {
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getAboutUs() {
                return this.getCategories.find(x => x.slug === 'annoucement')
            },
            getAboutUsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => b.id - a.id)
            },
        },
        async mounted() {
            setTimeout(function () {
                externalScript.collapsibile();
            }, 1000);
            await this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();

        },
    };
</script>

<style scoped>
    .banner-container {
        display: flex;
        justify-content: center;
        background-color: #f2ebff;
        height: 358px;
        align-items: center;
    }

    .banner-container .banner-content .banner-title h1 {
        font-size: 42px;
        font-weight: 300;
        line-height: 54px;
    }

    .banner-container .banner-content .banner-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .banner-container .banner-content {
        justify-content: center !important;
    }

    .annoucement-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .annoucement-list > li {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        text-decoration: none;
    }

    .image-wrapper {
        position: relative;
        width: 100%;
        height: 250px;
    }

    .overlay {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: white;
        left: 0;
        right: 0;
        background-color: rgba(95, 67, 147, 0.9);
        overflow: hidden;
        width: 0;
        z-index: 3;
        height: 100%;
        transition: .5s ease;
    }

    .image-wrapper:hover .overlay {
        width: 100%;
    }

    .image-wrapper > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
        object-position: center center;
    }

    .image-wrapper > span {
        color: purple;
        position: absolute;
        z-index: 4;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        background: white;
        width: 80px;
        text-align: center;
        height: 25px;
        font-weight: bold;
        background: #ffffff;
        z-index: 5;
        font-size: 12px !important;
        border-radius: 14px;
        border: 1px solid #5f4393;
        transform: translate(5px, 15px);
    }

    .annoucement-card {
        width: 50%;
        margin-top: 5% !important;
        margin-bottom: 3% !important;
        list-style-type: none;
    }

    .annoucement-card > a {
        text-decoration: none !important;
        color: black;
    }

    .annoucement-card:hover h3 {
        color: #a97aff !important;
        transition: ease-in 0.1s;
    }

    .annoucement-card > a > h3 {
        color: #5F4393;
        transition: ease-in 0.1s;
        margin-top: 30px;
    }

    .annoucement-card > a > p {
        color: #5F4393;
        margin-top: 10px;
        text-align: justify;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
    }

    @media only screen and (max-width: 800px) {
        .annoucement-card {
            width: 100%;
            margin-top: 5% !important;
            margin-bottom: 3% !important;
            list-style-type: none;
        }
        .annoucement-list{
            padding: 0px !important;
        }
    }

    @media only screen and (min-width: 800px) {
        .annoucement-card {
            width: 44%;
            margin-top: 5% !important;
            margin-bottom: 3% !important;
            list-style-type: none;
        }

        .annoucement-list {
            padding: 0px !important;
        }
    }

    @media only screen and (min-width: 1001px) {
        .annoucement-card {
            width: 46%;
            margin-top: 5% !important;
            margin-bottom: 3% !important;
            list-style-type: none;
        }

        .annoucement-list {
            padding: 0px !important;
        }
    }

    @media only screen and (min-width: 1501px) {
        .annoucement-card {
            width: 50%;
            margin-top: 5% !important;
            margin-bottom: 3% !important;
            list-style-type: none;
        }

        .annoucement-list {
            padding: 0px !important;
        }
    }
</style>