<template>
    <div class="accounts">
        <div class="banner-container">
            <div class="banner-content">
                <div v-for="item in getClientsPosts" :key="item.id" class="card" :class="card == item.id ? 'active-card' : ''" @click="changeCard(item.id), addCustomParameter(item.id)">
                    <span class="card-title" v-if="item.name.includes('E-Banking')">
                        <span class="card-title" sq="Aplikimi për E-Banking" en="Application for E-Banking" sr="Aplikacija za E-Banking">Application for Individual Account Opening</span>
                    </span>
                    <span class="card-title" v-if="item.name.includes('M-Banking')">
                        <span class="card-title" sq="Aplikimi për M-Banking" en="Application for M-Banking" sr="Aplikacija za M-Banking">Application for Individual Account Opening</span>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Biznesi')">
                        <span class="card-title" sq="Aplikimi për Kredi Biznesi" en="Application for Business Credit" sr="Prijava za otvaranje poslovnog kredita">Application for Business Credit</span>
                    </span>
                    <span class="card-title" v-if="item.name.includes('Overdraft')">
                        <span sq="Aplikimi për Overdraft" en="Application for Overdraft" sr="Prijava za odobrenje prekora?enja ra?una">Application for Overdraft</span>
                    </span>
                </div>
            </div>
        </div>
        <div>
            <div v-for="item in getClientsPosts" :key="item.id">
                <div v-if="card == 62 && item.id == 62">
                    <div>
                        <BusinessEBankingApplication />
                    </div>
                </div>
                <div v-if="card == 64 && item.id == 64">
                    <div>
                        <BusinessMBankingApplication />
                    </div>
                </div>
                <div v-if="card == 28 && item.id == 28">
                    <div>
                        <BusinessLoanApplication />
                    </div>
                </div>
                <div v-if="card == 29 && item.id == 29">
                    <div>
                        <BusinessOverdraftApplication />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import BusinessEBankingApplication from "@/components/Business/BusinessEBankingApplication.vue";
    import BusinessMBankingApplication from "@/components/Business/BusinessMBankingApplication.vue";
    import BusinessLoanApplication from "@/components/Business/BusinessLoanApplication.vue";
    import BusinessOverdraftApplication from "@/components/Business/BusinessOverdraftApplication.vue";
    import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                card: 1,
            }
        },
        components: {
            BusinessEBankingApplication,
            BusinessLoanApplication,
            BusinessMBankingApplication,
            BusinessOverdraftApplication
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            changeCard(e) {
                console.log(e);
                this.card = e;
            },
            addCustomParameter(i) {
                this.$router.push({ query: { form: i } })
            }
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getClients() {
                return this.getCategories.find(x => x.slug === 'aplikimet-online')
            },
            getClientsPosts() {
                return this.getCategories.filter(x => x.parent === this.getClients.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            }
        },
        async mounted() {
            this.card = this.getClientsPosts[0].id
            if (this.$route.query.form !== undefined) {
                this.changeCard(this.$route.query.form)
            }
            this.fetchCategories();
            await this.fetchPosts();
            await this.fetchMedias();
            setTimeout(() => externalScript.reloadLanguage(), 250);
        },
    };
</script>

<style>
    @import "../../assets/styles/individual-accounts.css";
</style>

<style scoped>

    .accounts .banner-container .banner-content {
        justify-content: center;
    }
</style>