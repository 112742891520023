<template>
    <div class="header">
        <div class="top-header-container">
            <div class="top-header">
                <div class="user-category">
                    <ul>
                        <li v-for="item in getParentCategories" :key="item.id" class="parent-category">
                            <router-link :class="userCategory == item.slug ? 'active' : ''" :to="'/'+item.slug" @click="userCategory = item.slug; selectedPage = getNavigationCategories[0]?.id; setLang()" aria-label="Navigation Categories">{{ item.name }}</router-link>
                        </li>
                    </ul>
                </div>
                <div class="contact">
                    <p id="phone-number">+383 38 74 77 77</p>
                    <div class="help">
                        <p id="help">info@pribank-ks.com</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-header-container">
            <div class="bottom-header">
                <div class="logo" @click="userCategory === 'individual' ? (selectedPage = getIndividualId) : (selectedPage = getBusinessId)">
                    <router-link :to="'/' + userCategory" aria-label="User Categories">
                        <img src="../../assets/images/logo.svg" width="189" height="44" alt="Logoja e Pribank" />
                    </router-link>
                </div>
                <div class="navbar">
                    <ul>
                        <li v-for="item in getNavigationCategories" :key="item.id" class="has-submenu" :class="selectedPage === item.id ? 'active ' + item.id : ' ' + item.id" @click="selectedPage = item.id">
                            <router-link class="item" v-if="!getCategories.filter(x => x.parent === item.id && item.slug !== 'home' && item.slug !== 'about-us' && item.slug !== 'clients' && item.slug !== 'klientet' && item.slug !== 'online-applications'  && item.slug!=='aplikimet-online' && item.slug !== 'ballina' && item.slug !== 'rreth-nesh').length" @click="selectedPage = item.id" :to="userCategory === 'individual' ? (item.slug === 'home' ? '/individual' :  '/' + userCategory + '/' +  item.slug) : (item.slug === 'ballina' ? '/business' :  '/' + userCategory + '/' +  item.slug)" aria-label="Post Slugs">
                                <span v-if="item.name == 'Ballina'">
                                    <span sq="Ballina" en="Home" sr="Kuca"></span>
                                </span>
                                <span v-if="item.name.includes('Rreth')">
                                    <span sq="Rreth Nesh" en="About Us" sr="O nama"></span>
                                </span>
                                <span v-if="item.name.includes('Individual')">
                                    <span sq="Klientët Individual" en="Individual Clients" sr="Individualni Klijenti"></span>
                                </span>
                                <span v-if="item.name.includes('biznesor')">
                                    <span sq="Klientët Biznesor" en="Business Clients" sr="Poslovni Klijenti"></span>
                                </span>
                                <span v-if="item.name.includes('Aplikimet')">
                                    <span sq="Aplikimet Online" en="Online Applications" sr="Online aplikacije"></span>
                                </span>
                            </router-link>
                            <span v-else class="item">
                                <span v-if="item.name.includes('Kujdesi')">
                                    <span sq="Kujdesi ndaj Klientit" en="Customer care" sr="Briga o kupcima"></span>
                                </span>
                            </span>
                            <ul class="nested-navbar" v-if="getCategories.filter(x => x.parent === item.id && item.slug !== 'home' && item.slug !== 'about-us' && item.slug !== 'clients' && item.slug !== 'klientet' && item.slug !== 'online-applications' && item.slug!=='aplikimet-online' && item.slug !== 'ballina' && item.slug !== 'rreth-nesh').length">
                                <li v-for="i in getCategories.filter(x => x.parent === item.id)" :key="i.id" class="nested-item onhover">
                                    <router-link class="link" @click="selectedPage = item.id" :to="userCategory === 'individual' ? (i.slug === 'home' ? '/individual' :  '/' + userCategory + '/' +  i.slug) : (i.slug === 'ballina' ? '/business' :  '/' + userCategory + '/' +  i.slug)" aria-label="Url Links">
                                        <span v-if="i.name == 'Ankesa dhe sugjerime'">
                                            <span sq="Ankesa dhe sugjerime" en="Complaints and suggestions" sr="Pritužbe i prijedlozi"></span>
                                        </span>
                                        <span v-if="i.name == 'Degët e bankës'">
                                            <span sq="Degët e bankës" en="Bank branches" sr="Filijale banaka"></span>
                                        </span>
                                        <span v-if="i.name == 'Mbrojtja e të dhënave'">
                                            <span sq="Mbrojtja e të dhënave" en="Data protection" sr="Zaštita podataka"></span>
                                        </span>
                                        <span v-if="i.name == 'Kushtet e Përgjithshme të Afarizmit Bankar'">
                                            <span sq="Kushtet e Përgjithshme të Afarizmit Bankar" en="General terms of banking services" sr="Opšti uslovi bankarskih usluga"></span>
                                        </span>
                                        <span v-if="i.name == 'Çmimorja e bankës'">
                                            <span sq="Çmimorja e bankës" en="Bank fees" sr="Naknade banke"></span>
                                        </span>
                                        <span v-if="i.name == 'Pyetje të zakonshme'">
                                            <span sq="Pyetje të zakonshme" en="Frequently asked questions" sr="Često postavljana pitanja"></span>
                                        </span>
                                        <span v-if="i.name == 'Lista SSI'">
                                            <span sq="Lista SSI" en="Lista SSI" sr="Lista SSI"></span>
                                        </span>
                                    </router-link>
                                </li>
                                <li class="nested-item onhover">
                                    <router-link to="/privacy" class="link" aria-label="Url Links">
                                        <span>
                                            <span sq="PriBank dhe Privatësia" en="PriBank and Privacy" sr="PriBank i privatnost"></span>
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class="selectedPage === 1136 ? 'active ' + 1136 : ' ' + 1136" @click="selectedPage = 1136">
                            <router-link to="/Announcement" class="item" aria-label="Url Links">
                                <span>
                                    <span sq="Njoftimet" en="Announcements" sr="Najave"></span>
                                </span>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="navbar">

                    <div style="display: flex; align-items: center;">
                        <select v-model="selectedLanguage" @change="setLangCookie(selectedLanguage)" style="font-size: 14px; font-weight: 400; line-height: 19.2px; letter-spacing: 0em; text-align: left; padding: 5px; height: 40px; border: none; cursor: pointer; color: #5f4393; font-size: 14px !important; font-weight: 800 !important;">
                            <option value="sq">SQ</option>
                            <option value="en">EN</option>
                            <option value="sr">SR</option>
                        </select>
                        <div class="eBanking">
                            <a href="https://pribank.banka-ks.com/Account/Login" target="_blank" id="ebtn">
                                <button id="eBanking">
                                    E-Banking<span style="display: flex; align-items: center;"><img src="../../assets/images/world.svg" width="18" height="18" /></span>
                                </button>
                            </a>
                            <div class="topnav-burger">
                                <div class="icon" @click="open()" style="margin-top: 10px;">
                                    <img src="../../assets/images/menu.png" alt="Menu" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="navbar" style="display: none;">
                <div class="topnav">
                    <div id="myLinks">
                        <div v-for="item in getNavigationCategories" :key="item.id">
                            <router-link class="item" v-if="!getCategories.filter(x => x.parent === item.id && item.slug !== 'home' && item.slug !== 'about-us' && item.slug !== 'clients' && item.slug !== 'klientet' && item.slug !== 'online-applications'  && item.slug!=='aplikimet-online' && item.slug !== 'ballina' && item.slug !== 'customer-care' && item.slug !== 'kujdesi-ndaj-klientit').length" @click="selectedPage = item.id" :to="userCategory === 'individual' ? (item.slug === 'home' ? '/individual' :  '/' + userCategory + '/' +  item.slug) : (item.slug === 'ballina' ? '/business' :  '/' + userCategory + '/' +  item.slug)" aria-label="Mobile Nav Links">

                                <span v-if="item.name == 'Ballina'">
                                    <span sq="Ballina" en="Home" sr="Početna"></span>
                                </span>
                                <span v-if="item.name == 'Rreth Nesh'">
                                    <span sq="Rreth Nesh" en="About Us" sr="O nama"></span>
                                </span>
                                <span v-if="item.name == 'Klientët Individual'">
                                    <span sq="Klientët Individual" en="Individual Clients" sr="Individualni klijenti"></span>
                                </span>
                                <span v-if="item.name == 'Aplikimet Online'">
                                    <span sq="Aplikimet Online" en="Online Applications" sr="Online aplikacije"></span>
                                </span>
                                <span v-if="item.name == 'Kujdesi ndaj Klientit'">
                                    <span sq="Kujdesi ndaj Klientit" en="Customer Care" sr="Naknade banke"></span>
                                </span>
                                <span v-if="item.name == 'Kujdesi ndaj klientit'">
                                    <span sq="Kujdesi ndaj Klientit" en="Customer Care" sr="Naknade banke"></span>
                                </span>
                                <span v-if="item.name == 'Pyetje të zakonshme'">
                                    <span sq="Pyetje të zakonshme" en="Frequently asked questions" sr="Često postavljana pitanja"></span>
                                </span>
                                <span v-if="item.name == 'Lista SSI'">
                                    <span sq="Lista SSI" en="SSI List" sr="Lista SSI"></span>
                                </span>
                                <span v-if="item.name == 'Klientët biznesor'">
                                    <span sq="Klientët biznesor" en="Business Clients" sr="Poslovni klijenti"></span>
                                </span>
                                <span v-if="item.name.includes('Aplikimet online')">
                                    {{item.name}}
                                    <span sq="Aplikimet Online" en="Online Applications" sr="Online aplikacije"></span>
                                </span>
                            </router-link>

                        </div>
                        <div id="ebmb" style="display: none;">
                            <a href="https://pribank.banka-ks.com/Account/Login" target="_blank" class="item" aria-label="Mobile Nav Links">
                                <span>
                                    <span>E-Banking</span>
                                </span>
                            </a>
                        </div>
                        <div class="has-submenu" :class="selectedPage === 1136 ? 'active ' + 1136 : ' ' + 1136" @click="selectedPage = 1136">
                            <router-link to="/Announcement" class="item" aria-label="Url Links">
                                <span>
                                    <span sq="Njoftimet" en="Announcements" sr="Najave"></span>
                                </span>
                            </router-link>
                        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { externalScript } from '../../assets/external';

    export default {
        methods: {
            ...mapActions(["fetchCategories"]),
            open() {
                externalScript.openmenu();
            },
            setLangCookie(ele) {
                const d = new Date();
                d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
                let expires = "expires=" + d.toUTCString();
                document.cookie = "lang=" + ele + ";" + expires + ";path=/";
                this.selectedLanguage = ele;
                externalScript.reloadLanguage();

                window.location.reload();
                
            },
            getLangCookie() {
                var abc = this.getCookie('lang');
                if (abc == "") {
                    this.setLangCookie('sq');
                }
                else {
                    this.selectedLanguage = abc;
                    return '';

                }
            },
            getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            setLang() {
                setTimeout(() => externalScript.reloadLanguage(), 300);
            },
        },
        data() {
            return {
                userCategory: 'individual',
                selectedPage: 0,
                selectedLanguage: ''
            }
        },
        computed: {
            ...mapGetters(["getCategories"]),
            getParentCategories() {
                return this.getCategories.filter(x => x.parent === 0 && x.id !== 3 && x.id !== 63 && x.id !== 70).sort((a, b) => a.id - b.id)
            },
            selectedCategory() {
                return this.getCategories.find(x => x.slug === this.userCategory);
            },

            getNavigationCategories() {
                return this.getCategories.filter(x => x.parent === this.selectedCategory.id).sort((a, b) => a.id - b.id)
            }
            ,
            getIndividualId() {
                return this.getCategories.find(x => x.slug === 'home').id;
            }
            ,
            getBusinessId() {
                return this.getCategories.find(x => x.slug === 'ballina').id;
            },
           
        },
        async mounted() {
            this.fetchCategories();
            this.getLangCookie();
            await setTimeout(()=> externalScript.reloadLanguage(), 300);
            this.selectedPage = this.getNavigationCategories[0]?.id;
        }
    };
</script>

<style>
    @import "../../assets/styles/style.css";

    .eBanking > a {
        text-decoration: none !important;
    }
    #ebmb {
        display:  none;
    }

    @media only screen and (max-width: 375px) {
        #ebtn {
            display: none !important;
        }

        #ebmb {
            display: unset;
        }
    }

    .header .bottom-header-container .bottom-header .navbar .eBanking button{
        font-size: 13px !important;
    }
</style>