import axios from "axios";

const state = {
  posts: [],
};

const getters = {
  getPosts: (state) => state.posts,
};

const actions = {
  // async fetchPosts({ commit }, id) {
  // 	try {
  // 		const data = await axios.get(`/wp/v2/posts?categories=${id}`)
  // 		if (data && !data.data.length)
  // 			data.data = [];
  // 		commit('updatePosts', data.data);
  // 	}
  // 	catch (error) {
  // 		console.log(error)
  // 	}
  // }
  async fetchPosts({ commit }) {
    try {
      const data = await axios.get(`/wp/v2/posts?per_page=100`);
      if (data && !data.data.length) data.data = [];
      commit("updatePosts", data.data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  updatePosts: (state, latest) => (state.posts = latest),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
