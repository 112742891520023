<template>
    <div class="footer">
        <!--<div class="top-footer-container">
            <div class="top-footer-content">-->
        <!-- <div class="top-header">

        </div> -->
        <!--<h1>Shkarkoni aplikacionin e PriBank</h1>
                <div class="download-app">
                    <img src="../../assets/images/appstore.svg" />
                    <img src="../../assets/images/playstore.svg" />
                </div>
            </div>
        </div>-->
        <div class="bottom-footer-container">
            <div class="bottom-footer">
                <div class="follow-us">
                    <h1 sq="Na ndiqni" en="Follow us" sr="Pratite nas"></h1>
                    <a href="https://www.facebook.com/PriBankKs" aria-label="Visit on Facebook"><img src="../../assets/images/facebook.svg" alt="facebook" /></a>
                    <a href="https://www.instagram.com/pribank_ks/" aria-label="Visit on Facebook"><img src="../../assets/images/insta.svg" alt="instagram" fill="white"/></a>
                    <a href="https://www.linkedin.com/company/pribank-ks" aria-label="Visit on Linkedin"><img src="../../assets/images/linkedin.svg" alt="linkedin" /></a>
                </div>
                <div class="contact-us">
                    <h1 sq="Kontakti dhe adresa" en="Contant and Address" sr="Kontakt i adresa"></h1>
                    <p>{{getEmail[0]?.title.rendered}}</p>
                    <p>{{getEmail2[0]?.title.rendered}}</p>
                    <p>{{getPhone[0]?.title.rendered}}</p>
                    <p>{{getPhone2[0]?.title.rendered}}</p>
                    <p>{{getAddress[0]?.title.rendered}}</p>
                </div>

                <div class="campaigns">
                    <h1 sq="Kampanjat" en="Campaigns" sr="Kampanja"></h1>
                    <span v-for="item in getBannerPosts" :key="item.id">
                        <router-link :to="'/individual/post/' + item.id" aria-label="Pribank Individual Campaigns"> <p :sq="item?.meta._sq_post_title" :en="item?.meta._en_post_title" :sr="item?.meta._sr_post_title"></p></router-link>
                    </span>
                    <span v-for="item in getBusinessBannerPosts" :key="item.id">
                        <router-link :to="'/business/post/' + item.id" aria-label="Pribank Business Campaigns"><p :sq="item?.meta._sq_post_title" :en="item?.meta._en_post_title" :sr="item?.meta._sr_post_title"></p></router-link>
                    </span>
                </div>

                <div class="campaigns">
                    <h1 sq="Ankesa dhe sugjerime" en="Complaints and suggestions" sr="Pritužbe i prijedlozi"></h1>
                    <p><router-link class="item-link" to="/individual/complaints-suggestions" sq="Ankesa Online" en="Online Complaint" sr="Online žalba"></router-link></p>
                    <p><router-link class="item-link" to="/individual/faq" sq="Pyetjet e shpeshta" en="FAQ" sr="Često postavljana pitanja"></router-link></p>
                    <p><router-link class="item-link" to="/Announcement" sq="Njoftime" en="Announcements" sr="Obavijesti"></router-link></p>
                </div>
            </div>

            <div class="fsdk" style="color: white; text-align:center; padding-bottom:48px">
                <p class="footer-p" id="footer-p"><span sq="PriBank Sh.A. merr pjesë detyrueshëm në" en="PriBank Sh.A. compulsory participation in" sr="PriBank Sh.A. obavezno sudjelovanje u"></span> <a href="https://fsdk.org/" aria-label="FSD Kosovo"><span sq="Fondin e Sigurimit të Depozitave të Republikës së Kosovës." en="Deposit Insurance Fund of the Republic of Kosovo." sr="Fond za osiguranje depozita Republike Kosovo."></span></a></p>
            </div>
            <div class="fsdk" style="color: white; text-align:center; padding-bottom:40px">
                <p class="footer-s" id="footer-s"><span sq="Politika e" en="Policy of" sr="Politika o"></span> <a href="https://cms.pribank-ks.com/wp-content/uploads/2024/03/Politika-e-ISMS-PriBank.pdf" aria-label="ISMS Kosovo"><span sq="ISMS - Sistemi i Menaxhimit të Sigurisë së Informacionit." en="ISMS - Information Security Management System." sr="ISMS - Sistem upravljanja informacionom sigurnošću."></span></a></p>
            </div>
        </div>
    </div>
</template>

<style>
    .item-link {
        text-decoration: none;
        color: #fff;
    }

    .campaigns a, .fsdk a {
        color: white;
    }

    .campaigns p {
        padding: 2px;
    }
</style>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { externalScript } from "../../assets/external";

    export default {
        methods: mapActions(["fetchCategories", "fetchPosts",]),
        data() {
            return {
                userCategory: 1,
                selectedPage: 0
            }
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts",]),
            getParentCategories() {
                return this.getCategories.filter(x => x.parent === 0 && x.id !== 1).sort((a, b) => a.id - b.id)
            },
            getNavigationCategories() {
                return this.getCategories.filter(x => x.parent !== 0 && x.id <= 10).sort((a, b) => a.id - b.id)
            },
            getBanner() {
                return this.getCategories.find(x => x.slug === 'banner')
            },
            getBusinessBanner() {
                return this.getCategories.find(x => x.slug === 'business-banner')
            },
            getBannerPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getBanner?.id).slice(0, 2);
            },
            getBusinessBannerPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getBusinessBanner?.id).slice(0, 2);
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            selectedCategory() {
                return this.getCategories.find(x => x.slug === this.userCategory);
            },
            isIndividual() {
                return this.$route.fullPath.includes('/individual');
            }
            ,
            getIndividualId() {
                return this.getCategories.find(x => x.slug === 'home').id;
            }
            ,
            getBusinessId() {
                return this.getCategories.find(x => x.slug === 'ballina').id;
            },
            getEmail() {
                return this.getPosts.filter(x => x.id == 969)
            },
            getEmail2() {
                return this.getPosts.filter(x => x.id == 972)
            },
            getPhone() {
                return this.getPosts.filter(x => x.id == 974)
            },
            getPhone2() {
                return this.getPosts.filter(x => x.id == 976)
            },
            getAddress() {
                return this.getPosts.filter(x => x.id == 978)
            },
        },
        mounted() {
            this.fetchCategories();
            this.fetchPosts();
            this.selectedPage = this.getNavigationCategories[0]?.id;
            this.userCategory = this.getChildCategories?.splice(0, 1).id
            externalScript.reloadLanguage();
        }
    };
</script>

<style>
    @import "../../assets/styles/style.css";
</style>